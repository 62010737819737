import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import {API_URL} from '../../../../../api/const';

import style from './TankModernization.module.css';

export const TankModernization = ({role, tankLvl}) => {
  const [load, setLoad] = useState(false);
  const [modernization, setModernization] = useState(false);
  const modernizationImages = useSelector(state => state.tankbase.modimg);

  useEffect(() => {
    axios(`${API_URL}queries/modernization/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        role,
      },
    })
      .then(({data}) => {
        const modernizationList = [];

        for (const key in data) {
          if (key) {
            modernizationList.push({
              level: data[key].level,
              variant: data[key].variant,
              name: data[key].name,
              image: data[key].image,
            });
          }
        }

        setModernization(modernizationList);
        setLoad(true);
      })
      .catch((error) => console.log(error.message));
  }, [load]);

  return (<>{
    load ? <>
      <div className={classNames(style.cell, style.bottomBorder)}>
        <img src={`https://${modernizationImages[modernization[0].image - 1].url}`}
          alt={modernization[0].name} />
      </div>
      <div className={classNames(style.cell, style.bottomBorder,
        style.rightBorder)}>
        <img src={`https://${modernizationImages[modernization[1].image - 1].url}`}
          alt={modernization[1].name} />
      </div>
      <div className={classNames(style.cell, style.bottomBorder)}>
        <img src={`https://${modernizationImages[modernization[2].image - 1].url}`}
          alt={modernization[2].name} />
      </div>
      <div className={classNames(style.cell, style.bottomBorder,
        style.rightBorder)}>
        <img src={`https://${modernizationImages[modernization[3].image - 1].url}`}
          alt={modernization[3].name} />
      </div>
      <div className={classNames(style.cell, style.bottomBorder)}>
        <img src={`https://${modernizationImages[modernization[4].image - 1].url}`}
          alt={modernization[4].name} />
      </div>
      <div className={classNames(style.cell, style.bottomBorder,
        style.rightBorder)}>
        <img src={`https://${modernizationImages[modernization[5].image - 1].url}`}
          alt={modernization[5].name} />
      </div>
      {
        tankLvl === '9' || tankLvl === '10' ? <>
          <div className={classNames(style.cell, style.bottomBorder)}>
            <img src={`https://${modernizationImages[modernization[6].image - 1].url}`}
              alt={modernization[6].name} />
          </div>
          <div className={classNames(style.cell, style.bottomBorder,
            style.rightBorder)}>
            <img src={`https://${modernizationImages[modernization[7].image - 1].url}`}
              alt={modernization[7].name} />
          </div>
        </> : <>
          <div className={classNames(style.cell, style.rightBorder,
            style.bottomBorder, style.cell7)}>только для 8-10 уровней</div>
        </>
      }
      {
        tankLvl === '10' ? <>
          <div className={classNames(style.cell, style.bottomBorder)}>
            <img src={`https://${modernizationImages[modernization[8].image - 1].url}`}
              alt={modernization[8].name} />
          </div>
          <div className={classNames(style.cell, style.bottomBorder)}>
            <img src={`https://${modernizationImages[modernization[9].image - 1].url}`}
              alt={modernization[9].name} />
          </div>
        </> : <>
          <div className={classNames(style.cell,
            style.bottomBorder, style.cell8)}>только для 8-10 уровней</div>
        </>
      }
    </> : ''
  }</>);
};

TankModernization.propTypes = {
  role: PropTypes.string,
  tankLvl: PropTypes.string,
};
