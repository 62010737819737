import style from './Pagetitle.module.css';
import PropTypes from 'prop-types';

export const Pagetitle = ({title}) => (
  <div className={style.bg}>
    <h2 className={style.title}>{title}</h2>
  </div>
);

Pagetitle.propTypes = {
  title: PropTypes.string,
};
