import {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import {updateLevelSelected} from '../../../store/tankSelected';

import style from './LevelSelect.module.css';

export const LevelSelect = ({updateData}) => {
  const [selectedLevel, setSelectedLevel] = useState(false);
  const dispatch = useDispatch();
  const levels = [
    {level: '1', name: 'I'},
    {level: '2', name: 'II'},
    {level: '3', name: 'III'},
    {level: '4', name: 'IV'},
    {level: '5', name: 'V'},
    {level: '6', name: 'VI'},
    {level: '7', name: 'VII'},
    {level: '8', name: 'VIII'},
    {level: '9', name: 'IX'},
    {level: '10', name: 'X'},
  ];

  const handleSelected = e => {
    const level = e.target.closest('li').dataset.level;
    if (selectedLevel === level) {
      setSelectedLevel(false);
      updateData(false);
    } else {
      setSelectedLevel(level);
      dispatch(updateLevelSelected(level));
      updateData(level);
    }
  };

  return (
    <>
      {
        levels.map((elem, i) => (
          <li key={i} className={selectedLevel === elem.level ?
            style.selectedElem : style.itemElem
          } onClick={handleSelected} data-level={elem.level}>
            <span>{elem.name} уровень</span>
          </li>
        ))
      }
    </>
  );
};

LevelSelect.propTypes = {
  updateData: PropTypes.func,
};
