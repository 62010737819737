import classNames from 'classnames';
import {Link} from 'react-router-dom';

import Mainmenu from './Mainmenu';

import style from './Header.module.css';

export const Header = () => <header className={style.header}>
  <div className={classNames('container', style.container)}>
    <div className={style.menu}>
      <Mainmenu />
    </div>

    <Link to={`/`}
      className={classNames(style.title, 'title')}>WoTBrains</Link>

    <div className={style.search}>
    </div>
  </div>
</header>;
