const initialState = {
  list: [],
  loaded: false,
};

const UPDATE_TANKSLIST = 'UPDATE_TANKSLIST';
const CLEAR_TANKSLIST = 'CLEAR_TANKSLIST';

export const updateTankslist = (list) => ({
  type: UPDATE_TANKSLIST,
  list,
});

export const clearTankslist = () => ({
  type: CLEAR_TANKSLIST,
});

export const tankslistReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TANKSLIST:
      return {
        ...state,
        list: action.list,
        loaded: true,
      };

    case CLEAR_TANKSLIST:
      return {
        ...state,
        list: [],
        loaded: false,
      };

    default:
      return state;
  }
};
