import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

import {API_URL, BASE_IMAGES, FLAGS_IMAGES} from '../../../../api/const';
import TankModernization from './TankModernization';
import StreamerModernization from './StreamerModernization';
import StreamerEquipment from './StreamerEquipment';

import style from './TankInfo.module.css';

export const TankInfo = ({tank, nationals, types, roles, closeInfo}) => {
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState(false);

  const getNational = id => nationals.find(elem => elem.id === id);

  const getType = id => types.find(elem => elem.id === id);

  const getRole = id => roles.find(elem => elem.id === id);

  const levels = [
    {level: '1', name: 'I'},
    {level: '2', name: 'II'},
    {level: '3', name: 'III'},
    {level: '4', name: 'IV'},
    {level: '5', name: 'V'},
    {level: '6', name: 'VI'},
    {level: '7', name: 'VII'},
    {level: '8', name: 'VIII'},
    {level: '9', name: 'IX'},
    {level: '10', name: 'X'},
  ];

  const getLevel = id => levels.find(elem => elem.level === id);

  useEffect(() => {
    axios(`${API_URL}queries/tankinfo/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        id: tank,
      },
    })
      .then(({data}) => {
        setLoad(true);
        setInfo({
          id: data.id,
          level: data.level,
          name: data.name,
          national: data.national,
          prem: data.prem,
          reward: data.reward,
          role: data.role,
          type: data.type,
          wotid: data.wotid,
        });
      })
      .catch((error) => console.log(error.message));
  }, []);

  return (<>
    <div className={style.overlay}>
    </div>
    <div className={style.inner}>
      <div className={style.container}>
        <div className={style.body}>
          <span className={style.cross} onClick={closeInfo}>X</span>
          {load === true ? <>
            <h3 className={style.title}>Информация о танке {info.name}</h3>
            <div className={style.info}>
              <div className={style.image}>
                <img
                  src={`https://${BASE_IMAGES}${info.wotid}/${info.wotid}_image_resized.png`} />
                <span className={style.flag}>
                  <img src={`https://${FLAGS_IMAGES}${getNational(info.national).flag}`}
                    className={style.flag} alt='Флаг' />
                </span>
              </div>
              <div className={style.infoBlock}>
                <p className={style.h4}>
                  <span>Уровень: </span> {getLevel(info.level).name}
                </p>
                <p className={style.h4}>
                  <span>Тип: </span> {getType(info.type).type}
                </p>
                <p className={style.h4}>
                  <span>Роль: </span> {getRole(info.role).role}
                </p>
                <p className={style.h4}>
                  <span>Премиум: </span>
                  {info.prem === 1 ? 'Да' : 'Нет'}
                </p>
                <p className={style.h4}>
                  <span>Наградная техника: </span>
                  {info.reward === 1 ? 'Да' : 'Нет'}
                </p>
              </div>
            </div>
            <div className={style.modernInfo}>
              <div className={style.modernTitle}>
                <h3>Информация по полевой модернизации</h3>
              </div>
              <div className={style.modernNull}></div>
              <div className={classNames(style.modernLevel1,
                style.bottomBorder, style.rightBorder)}>Уровень II</div>
              <div className={classNames(style.modernLevel2,
                style.bottomBorder, style.rightBorder)}>Уровень IV</div>
              <div className={classNames(style.modernLevel3,
                style.bottomBorder, style.rightBorder)}>Уровень V</div>
              <div className={classNames(style.modernLevel4,
                style.bottomBorder, style.rightBorder)}>Уровень VII</div>
              <div className={classNames(style.modernLevel5,
                style.bottomBorder)}>Уровень VIII</div>
              <TankModernization role={info.role} tankLvl={info.level} />
              <StreamerModernization tank={info.id} />
            </div>
            <div className={style.equipmentInfo}>
              <h3 className={style.equipmentTitle}>
                Информация по дополнительному оборудованию
              </h3>
              <ul className={style.equipmentBody}>
                <li className={classNames(style.equipmentSubtitle,
                  style.equipmentStreamerTitle, style.bottomBorder,
                  style.rightBorder)}>Стример</li>
                <li className={classNames(style.equipmentSubtitle,
                  style.equipmentVariantTitle, style.bottomBorder,
                  style.rightBorder)}>Вариант</li>
                <li className={classNames(style.equipmentSubtitle,
                  style.equipmentSlotTitle, style.bottomBorder,
                  style.rightBorder)}>Слот 1</li>
                <li className={classNames(style.equipmentSubtitle,
                  style.equipmentSlotTitle, style.bottomBorder,
                  style.rightBorder)}>Слот 2</li>
                <li className={classNames(style.equipmentSubtitle,
                  style.bottomBorder, style.equipmentSlotTitle)}>Слот 3</li>
              </ul>
              <StreamerEquipment tank={info.id} />
            </div>
          </> : <h3>Упс, что-то пошло не так</h3>}
        </div>
      </div>
    </div>
  </>);
};

TankInfo.propTypes = {
  tank: PropTypes.string,
  nationals: PropTypes.array,
  types: PropTypes.array,
  roles: PropTypes.array,
  closeInfo: PropTypes.func,
};
