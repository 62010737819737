import axios from 'axios';
import {API_URL} from '../../api/const.js';
import {updateTankbase} from './tankbaseReducer';

export const tankbaseRequestAsync = () =>
  (dispatch) => {
    const url = `${API_URL}queries/tankbase/`;

    return axios(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({data}) => {
        const nationals = [];
        const roles = [];
        const types = [];
        const modimg = [];
        const equipimg = [];

        for (const key in data.nationals) {
          if (key) {
            nationals.push({
              id: data.nationals[key].id,
              name: data.nationals[key].name,
              flag: data.nationals[key].flag,
            });
          }
        }

        for (const key in data.roles) {
          if (key) {
            roles.push({
              id: data.roles[key].id,
              type: data.roles[key].type,
              role: data.roles[key].role,
            });
          }
        }

        for (const key in data.tanktypes) {
          if (key) {
            types.push({
              id: data.tanktypes[key].id,
              type: data.tanktypes[key].type,
              icon: data.tanktypes[key].icon,
            });
          }
        }

        for (const key in data.modimg) {
          if (key) {
            modimg.push({
              id: data.modimg[key].id,
              url: data.modimg[key].url,
            });
          }
        }

        for (const key in data.equipimg) {
          if (key) {
            equipimg.push({
              id: data.equipimg[key].id,
              alt: data.equipimg[key].alt,
              url: data.equipimg[key].url,
            });
          }
        }

        dispatch(updateTankbase(nationals, roles, types, modimg, equipimg));
      })
      .catch((error) => console.log(error.message));
  };
