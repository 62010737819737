import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {updateMenu} from '../../../store/menu';
import style from './Mainmenu.module.css';

export const Mainmenu = () => {
  let menuShowed = useSelector(state => state.menu.showed);
  const dispatch = useDispatch();

  const linkClick = () => {
    menuShowed ? dispatch(updateMenu(false)) : '';
  };

  const mouseClick = e => {
    const target = e.target;
    if (target.closest('#menutitle')) {
      if (menuShowed) {
        dispatch(updateMenu(false));
        menuShowed = false;
      } else {
        dispatch(updateMenu(true));
        menuShowed = true;
      }
    } else if (!target.closest('#menuclick')) {
      dispatch(updateMenu(false));
      menuShowed = false;
    }
  };

  useEffect(() => {
    window.addEventListener('click', mouseClick);

    return () => {
      window.removeEventListener('click', mouseClick);
    };
  }, []);

  return (
    <div className={style.mainmenu}>
      <span id='menutitle' className={style.menutitle}>Menu</span>
      {
        menuShowed ? (
          <ul className={style.menulist} id='menulist'>
            <li className={style.menuitem}>
              <Link to='/' onClick={linkClick}>Главная</Link>
            </li>

            <li className={style.menuitem}>
              <Link to='tanks' onClick={linkClick}>База танков</Link>
            </li>

            <li className={style.menuitem}>
              <Link to='streamers' onClick={linkClick}>Стримеры</Link>
            </li>
          </ul>
        ) : ''
      }
    </div>);
};
