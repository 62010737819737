import {configureStore} from '@reduxjs/toolkit';
import {menuReducer} from './menu';
import {tankbaseReducer} from './tankbase/tankbaseReducer';
import {tankSelectedReducer} from './tankSelected';
import {tankslistReducer} from './tankslist/tankslistReducer';

const store = configureStore({
  reducer: {
    tankbase: tankbaseReducer,
    menu: menuReducer,
    tankSelected: tankSelectedReducer,
    tankslist: tankslistReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
});

export default store;
