export const API_URL = 'https://edik.pro/wotbrains_api/';

// Путь к файлам с фото стримеров
export const STREAMERS_PHOTO_PATH = '/wotbrains_admin/images/streamers/';

// Путь к сайту WOT
export const WOT_SITE = 'tanki.su';

// Путь к статистике игрогов WoT
export const WOT_USERS_STAT_PATH = 'tanki.su/ru/community/accounts/';

// Путь к Tankopedia
export const TANKOPEDIA_PATH = 'tanki.su/ru/tankopedia/';

// Путь к папке с картинками танков
export const BASE_IMAGES = 'ru-wotp.lesta.ru/dcont/tankopedia_images/';

// Путь к папке с картинками флагов танков
// eslint-disable-next-line max-len
export const FLAGS_IMAGES = 'ru-wotp.lesta.ru/static/5.117.1_a28195/wotp_static/img/core/frontend/scss/common/components/icons/img/flags/';

// Путь к картинкам оборудования
export const EQUIPMENT_PATH = 'content-wg.gcdn.co/locdoc/equipment/img/';
