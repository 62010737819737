const initialState = {
  nationals: [],
  roles: [],
  types: [],
  modimg: [],
  equipimg: [],
  loaded: false,
};

const UPDATE_TANKBASE = 'UPDATE_TANKBASE';
const CLEAR_TANKBASE = 'CLEAR_TANKBASE';

export const updateTankbase = (nationals, roles, types, modimg, equipimg) => ({
  type: UPDATE_TANKBASE,
  nationals,
  roles,
  types,
  modimg,
  equipimg,
});

export const clearTankbase = () => ({
  type: CLEAR_TANKBASE,
});

export const tankbaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TANKBASE:
      return {
        ...state,
        nationals: action.nationals,
        roles: action.roles,
        types: action.types,
        modimg: action.modimg,
        equipimg: action.equipimg,
        loaded: true,
      };

    case CLEAR_TANKBASE:
      return {
        ...state,
        nationals: [],
        roles: [],
        types: [],
        modimg: [],
        equipimg: [],
        loaded: false,
      };

    default:
      return state;
  }
};
