import {useState} from 'react';
import PropTypes from 'prop-types';

import style from './TanksList.module.css';

import {FLAGS_IMAGES} from '../../../api/const';
import TankInfo from './TankInfo';

export const TanksList = ({tanksList, nationals, types, roles}) => {
  const [tankId, setTankId] = useState(false);

  const getNational = id => nationals.find(elem => elem.id === id);

  const getType = id => types.find(elem => elem.id === id);

  const getRole = id => roles.find(elem => elem.id === id);

  const levels = [
    {level: '1', name: 'I'},
    {level: '2', name: 'II'},
    {level: '3', name: 'III'},
    {level: '4', name: 'IV'},
    {level: '5', name: 'V'},
    {level: '6', name: 'VI'},
    {level: '7', name: 'VII'},
    {level: '8', name: 'VIII'},
    {level: '9', name: 'IX'},
    {level: '10', name: 'X'},
  ];

  const getLevel = id => levels.find(elem => elem.level === id);

  const handleClick = e => {
    const tankId = e.target.closest('ul').dataset.tankid;
    setTankId(tankId);
    scroll(0, 0);
  };

  const closeInfo = () => {
    setTankId(false);
  };

  return (<>
    <ul className={style.list} >
      {
        tanksList.map(elem => <li className={style.listItem} key={elem.id}
          onClick={handleClick}>
          <ul className={style.info} data-tankid={elem.id}>
            <li><img className={style.infoImage}
              src={`https://${FLAGS_IMAGES}${getNational(elem.national).flag}`} /></li>
            <li className={style.infoLevel}>{getLevel(elem.level).name}</li>
            <li className={style.infoName}>{elem.name}</li>
            <li className={style.infoType}>{getType(elem.type).type}</li>
            <li className={style.infoRole}>
              {getRole(elem.role) ? getRole(elem.role).role : '-'}
            </li>
            <li className={elem.prem === '1' ? style.infoPremYes :
              style.infoPremNo}>{elem.prem === '1' ? 'прем' :
                'не прем'}</li>
            <li className={style.infoReward}>
              {elem.reward === '1' ? 'наградной' : '-'}
            </li>
          </ul>
        </li>)
      }
    </ul>
    {tankId ? <TankInfo tank={tankId} nationals={nationals}
      types={types} roles={roles} closeInfo={closeInfo} /> : ''}
  </>);
};

TanksList.propTypes = {
  tanksList: PropTypes.array,
  nationals: PropTypes.array,
  types: PropTypes.array,
  roles: PropTypes.array,
};
