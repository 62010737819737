import {Link} from 'react-router-dom';
import classNames from 'classnames';

import Popular from './Popular';
import style from './Footer.module.css';

export const Footer = () => {
  const goUp = () => scroll(0, 0);

  return (<footer className={style.footer}>
    <div className={classNames('container', style.container)}>
      <div className={style.column}>
        <h3 className={classNames(style.title, 'title')}>Категории</h3>

        <ul className={style.list}>
          <li>
            <Link to="tanks" className={style.link} onClick={goUp}>
              Выбор модернизации
            </Link>
          </li>
          <li>
            <a href="#" className={style.link}>Навыки экипажа</a>
          </li>
          <li>
            <a href="#" className={style.link}>Оборудование</a>
          </li>
          <li>
            <a href="#" className={style.link}>Бустеры</a>
          </li>
          <li>
            <Link to="streamers" className={style.link} onClick={goUp}>
              Стримеры
            </Link>
          </li>
        </ul>
      </div>
      <div className={style.column}>
        <h3 className={classNames('title', style.title)}>Популярное</h3>
        <Popular />
      </div>
      <div className={style.column}>
        <h3 className={classNames('title', style.title)}>О нас</h3>
        <p className={style.text}>Мы - небольшая команда любителей игры
          World of Tanks (Мир танков) - немолодой, но начинающий стример
          с ником Cmapnep и его сын Станислав. Данный сайт создан для помощи
          игрокам в подборе оборудования и настроек полевой модернизации,
          обмена другой информацией об игре.</p>
        <p className={style.text}>Хорошей благодарностью за наш труд будет <a
          href="https://www.donationalerts.com/r/cmapnep"
          target="_blank" rel="noopener noreferrer"
          className={style.link}>финансовая
          поддержка</a> нашего проекта.</p>
      </div>
      <div className={style.column}>
        <h3 className={classNames('title', style.title)}>Контакты</h3>
        <p className={style.list}>Возникли вопросы по работе сайта, хотите
          помочь в развитии нашего проекта, пишите нам в социальной сети
          ВКонтакте</p>
        <ul className={style.list}>
          <li>
            <a href="https://vk.com/wotbrains" className={style.link}
              target="_blank" rel="noopener noreferrer">
              Страница сообщества в ВК</a>
          </li>
          <li>
            <a href="https://vk.com/cmapneptv" className={style.link}
              target="_blank" rel="noopener noreferrer">
              Cmapnep</a>
          </li>
          <li>
            <a href="https://vk.com/id218203242" className={style.link}
              target="_blank" rel="noopener noreferrer">
              Станислав</a>
          </li>
        </ul>
      </div>
    </div>

    <div className={style.copyright}>
      Сделано <a href="https://edik.pro"
        target="_blank" rel="noopener noreferrer">edik.pro</a>
    </div>
  </footer>);
};
