import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {API_URL} from '../../api/const';
import Pagetitle from '../../components/Main/Pagetitle';

import style from './Content.module.css';
import Button from '../../components/Button';

export const Content = () => {
  const {type, id} = useParams();
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState([]);

  const dateToISO = d => {
    const date = new Date(d);
    return date.toISOString();
  };

  useEffect(() => {
    axios(`${API_URL}queries/content/single/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        id,
      },
    })
      .then(({data}) => {
        setLoad(true);
        setInfo(data[0]);
      })
      .catch((error) => console.log(error.message));
  }, [type, id]);

  return (
    <>
      <Pagetitle title={type === 'news' ? 'Новости' : 'Статьи'} />
      <div className='container'>
        {load && info.id > 0 ?
          <div key={info.id} className={style.body}>
            <div className={style.picture}>
              <img src={`${API_URL}images/content/${info.picture}`} />
            </div>
            <div className={style.content}>
              <h3 className={style.title}>{info.title}</h3>
              <span className={style.autor}>{info.author}</span>
              <div className={style.text}
                dangerouslySetInnerHTML={{__html: info.text}}></div>
              <div className={style.info}>
                <time className={style.date}
                  dateTime={dateToISO(info.published)}>
                  {info.published}
                </time>
                <span className={style.views}>{info.views}</span>
                <span className={style.comments}>{info.comments}</span>
              </div>
              <Button page='/' value='На главную' styles={style.more} />
            </div>
          </div> : 'Загрузка ...'
        }
      </div>
    </>
  );
};
