import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {updateTanktypeSelected} from '../../../store/tankSelected';

import style from './TypeSelect.module.css';

export const TypeSelect = ({types, updateData}) => {
  const [selectedType, setSelectedType] = useState(false);
  const dispatch = useDispatch();

  const handleSelected = e => {
    const tanktype = e.target.closest('li').dataset.tanktype;
    if (selectedType === tanktype) {
      setSelectedType(false);
      updateData(false);
    } else {
      setSelectedType(tanktype);
      dispatch(updateTanktypeSelected(tanktype));
      updateData(tanktype);
    }
  };

  return (
    <>
      {
        types.map((elem, i) => (
          <li key={i} className={selectedType === elem.id ?
            style.selectedElem : style.itemElem
          } onClick={handleSelected} data-tanktype={elem.id}>
            <span>{elem.type}</span>
          </li>
        ))
      }
    </>
  );
};

TypeSelect.propTypes = {
  types: PropTypes.array,
  updateData: PropTypes.func,
};
