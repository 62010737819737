import PropTypes from 'prop-types';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {updateNationalSelected} from '../../../store/tankSelected';
import {FLAGS_IMAGES} from '../../../api/const';

import style from './NationalSelect.module.css';

export const NationalSelect = ({nationals, updateData}) => {
  const [selectedNational, setSelectedNational] = useState(false);
  const dispatch = useDispatch();

  const handleSelected = e => {
    const national = e.target.closest('li').dataset.national;
    if (selectedNational === national) {
      setSelectedNational(false);
      updateData(false);
    } else {
      setSelectedNational(national);
      dispatch(updateNationalSelected(national));
      updateData(national);
    }
  };

  return (
    <>
      {
        nationals.map((elem, i) => (
          <li key={i} className={selectedNational === elem.id ?
            style.selectedElem : style.itemElem
          } onClick={handleSelected} data-national={elem.id}>
            <img className={style.itemImage}
              src={`https://${FLAGS_IMAGES}${elem.flag}`} />
            <span>{elem.name}</span>
          </li>
        ))
      }
    </>
  );
};

NationalSelect.propTypes = {
  nationals: PropTypes.array,
  updateData: PropTypes.func,
};
