const initialState = {
  showed: false,
};

const UPDATE_MENU = 'UPDATE_MENU';
const CLEAR_MENU = 'CLEAR_MENU';

export const updateMenu = showed => ({
  type: UPDATE_MENU,
  showed,
});

export const clearNationals = () => ({
  type: CLEAR_MENU,
  showed: false,
});

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MENU:
      return {
        ...state,
        showed: action.showed,
      };

    case CLEAR_MENU:
      return {
        ...state,
        showed: action.showed,
      };

    default:
      return state;
  }
};
