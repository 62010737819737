import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {tankbaseRequestAsync} from '../../store/tankbase/tankbaseActions';
import {tankslistRequestAsync} from '../../store/tankslist/tankslistAction';
import {LevelSelect} from './LevelSelect/LevelSelect';
import NationalSelect from './NationalSelect';

import style from './TankSelect.module.css';
import {TanksList} from './TanksList/TanksList';
import {TypeSelect} from './TypeSelect/TypeSelect';

export const TankSelect = () => {
  const nationals = useSelector(state => state.tankbase.nationals);
  const types = useSelector(state => state.tankbase.types);
  const roles = useSelector(state => state.tankbase.roles);
  const loaded = useSelector(state => state.tankbase.loaded);
  const tanksList = useSelector(state => state.tankslist.list);
  const [nationalSelected, setNationalSelected] = useState(false);
  const [typeSelected, setTypeSelected] = useState(false);
  const [levelSelected, setLevelSelected] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const dispatch = useDispatch();

  const updateNational = (value) => {
    setNationalSelected(value);
  };

  const updateType = (value) => {
    setTypeSelected(value);
  };

  const updateLevel = (value) => {
    setLevelSelected(value);
  };

  useEffect(() => {
    if (loaded !== true) {
      dispatch(tankbaseRequestAsync());
    } else {
      dispatch(tankslistRequestAsync(nationalSelected, typeSelected,
        levelSelected));
      if (!nationalSelected && !typeSelected && !levelSelected) {
        setSearchStatus(false);
      } else {
        setSearchStatus(true);
      }
    }
  }, [nationalSelected, typeSelected, levelSelected]);

  return (
    <>
      <div className={style.select}>
        <div className={style.selectItem}>
          <h4 className={style.itemTitle}>Выберите нацию</h4>
          <ul className={style.listItem}>
            {
              loaded ? <NationalSelect nationals={nationals}
                updateData={updateNational} /> :
                <span>Идет загрузка ...</span>
            }
          </ul>
        </div>

        <div className={style.selectItem}>
          <h4 className={style.itemTitle}>Выберите тип техники</h4>
          <ul className={style.listItem}>
            {
              loaded ? <TypeSelect types={types} updateData={updateType} /> :
                <span>Идет загрузка ...</span>
            }
          </ul>
        </div>

        <div className={style.selectItem}>
          <h4 className={style.itemTitle}>Выберите уровень</h4>
          <ul className={style.listItem}>
            <LevelSelect updateData={updateLevel} />
          </ul>
        </div>
      </div>

      <div className={style.searchResult}>
        {searchStatus ? tanksList.length > 0 ? <TanksList tanksList={tanksList}
          nationals={nationals} types={types} roles={roles} /> :
          <h3>По заданным параметрам ничего не найдено</h3> :
          <h3>Не заданы параметры поиска</h3>}
      </div>
    </>
  );
};
