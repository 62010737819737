import {useEffect, useState} from 'react';
import axios from 'axios';

import {API_URL} from '../../../api/const';

import style from './Popular.module.css';
import {Link} from 'react-router-dom';

export const Popular = () => {
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    axios(`${API_URL}queries/popular/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(({data}) => {
        setLoad(true);
        const popularList = [];

        for (const key in data) {
          if (key) {
            popularList.push({
              id: data[key].id,
              categoryID: data[key].categoryID,
              categoryName: data[key].categoryName,
              title: data[key].title,
              picture: data[key].picture,
            });
          }
        }
        setInfo(popularList);
      })
      .catch((error) => console.log(error.message));
  }, []);

  return (<ul>
    {load && info.length > 0 ? info.map(elem => (
      <li key={elem.id} className={style.item}>
        <Link to={`content/${elem.categoryName}/${elem.id}`}>
          <img src={`${API_URL}images/content/${elem.picture}`}
            alt='Картинка' />
        </Link>
        <Link to={`content/${elem.categoryName}/${elem.id}`}>
          {elem.title}
        </Link>
      </li>
    )) : 'Загрузка ...'}
  </ul>);
};
