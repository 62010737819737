const initialState = {
  national: false,
  tanktype: false,
  level: false,
};

const UPDATE_SELECTED = 'UPDATE_SELECTED';
const UPDATE_NATIONAL = 'UPDATE_NATIONAL';
const UPDATE_TANKTYPE = 'UPDATE_TANKTYPE';
const UPDATE_LEVEL = 'UPDATE_LEVEL';
const CLEAR_SELECTED = 'CLEAR_SELECTED';

export const updateTankSelected = (national, tanktype, level) => ({
  type: UPDATE_SELECTED,
  national,
  tanktype,
  level,
});

export const updateNationalSelected = national => ({
  type: UPDATE_NATIONAL,
  national,
});

export const updateTanktypeSelected = tanktype => ({
  type: UPDATE_TANKTYPE,
  tanktype,
});

export const updateLevelSelected = level => ({
  type: UPDATE_LEVEL,
  level,
});

export const clearTankSelected = () => ({
  type: CLEAR_SELECTED,
});

export const tankSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED:
      return {
        ...state,
        national: action.national,
        tanktype: action.tanktype,
        level: action.level,
      };

    case UPDATE_NATIONAL:
      return {
        ...state,
        national: action.national,
      };

    case UPDATE_TANKTYPE:
      return {
        ...state,
        tanktype: action.tanktype,
      };

    case UPDATE_LEVEL:
      return {
        ...state,
        level: action.level,
      };

    case CLEAR_SELECTED:
      return {
        ...state,
        national: false,
        tanktype: false,
        level: false,
      };

    default:
      return state;
  }
};
