import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {API_URL} from '../../../api/const';

import style from './News.module.css';
import SectionTitle from '../SectionTitle';
import Announce from '../../Announce';
import Button from '../../Button';

export const News = ({title, category}) => {
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    axios(`${API_URL}queries/content/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        category,
        limit: 6,
      },
    })
      .then(({data}) => {
        setLoad(true);
        const newsList = [];
        let num = 1;

        for (const key in data) {
          if (key) {
            newsList.push({
              id: data[key].id,
              categoryID: data[key].categoryID,
              categoryName: data[key].categoryName,
              categoryTitle: data[key].categoryTitle,
              themeID: data[key].themeID,
              themeName: data[key].themeName,
              themeTitle: data[key].themeTitle,
              author: data[key].author,
              published: data[key].published,
              title: data[key].title,
              preview: data[key].preview,
              picture: data[key].picture,
              views: data[key].views,
              comments: data[key].comments,
              num,
            });
            num++;
          }
        }
        setInfo(newsList);
      })
      .catch((error) => console.log(error.message));
  }, []);

  return (<>
    <section className={style.news}>
      <div className='container'>
        <SectionTitle text={title} />
        <div className={style.body}>
          {load && info.length > 0 ? info.map(elem => (
            <div key={elem.id} className={style.item}>
              <Announce size={elem.num === 3 || elem.num === 4 ?
                'big' : 'small'} category='news' content={elem} />
            </div>
          )) : 'Загрузка ...'}
        </div>
        <Button styles={style.more} className={style.more}
          page='/posts/news/all' value='Все новости' />
      </div>
    </section>
  </>
  );
};

News.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
};
