import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './Button.module.css';

export const Button = ({styles, value, page}) => {
  const goTop = () => scroll(0, 0);

  return (
    <button className={classNames(style.btn, styles)}
      onClick={goTop}>
      <Link to={`${page}`} className={style.link}>{value}</Link>
    </button>
  );
};

Button.propTypes = {
  styles: PropTypes.string,
  value: PropTypes.string,
  page: PropTypes.string,
};
