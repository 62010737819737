import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

import {API_URL} from '../../../../../api/const';

import style from './StreamerModernization.module.css';
import ImgYes from './img/yes.png';

export const StreamerModernization = ({tank}) => {
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    axios(`${API_URL}queries/streamermodernization/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        id: tank,
      },
    })
      .then(({data}) => {
        setLoad(true);
        const modernizationTable = [];

        for (const key in data) {
          if (key) {
            modernizationTable.push({
              id: data[key].id,
              streamer: data[key].streamer,
              level11: data[key].level11,
              level12: data[key].level12,
              level21: data[key].level21,
              level22: data[key].level22,
              level31: data[key].level31,
              level32: data[key].level32,
              level41: data[key].level41,
              level42: data[key].level42,
              level51: data[key].level51,
              level52: data[key].level52,
            });
          }
        }

        setInfo(modernizationTable);
      })
      .catch((error) => console.log(error.message));
  }, [load]);

  return (
    <>{load && info.length > 0 ? info.map(elem => (<>
      <div key={elem.id} className={classNames(style.cell,
        style.rightBorder)}>{elem.streamer}</div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level11 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level12 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level21 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level22 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level31 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level32 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level41 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level42 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={classNames(style.cell, style.rightBorder)}>
        {elem.level51 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
      <div className={style.cell}>
        {elem.level52 === '1' ?
          <img src={ImgYes} className={style.image} /> : ''}
      </div>
    </>)) : <div className={style.noinfo}>
      Информация по стримерским модернизациям для данного танка
      в базе отсутствует
    </div>}</>
  );
};

StreamerModernization.propTypes = {
  tank: PropTypes.string,
};
