import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Layout from './components/Layout';

import './App.css';

import Homepage from './pages/Homepage';
import Tanks from './pages/Tanks';
import Equipment from './pages/Equipment';
import Modernizations from './pages/Modernizations';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Notfoundpage from './pages/Notfoundpage';
import Content from './pages/Content';
import Posts from './pages/Posts';
import {Streamers} from './pages/Streamers/Streamers';

function App() {
  return (
    <div className='wrapper'>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path='tanks' element={<Tanks />} />
          <Route path='content/:type/:id' element={<Content />} />
          <Route path='posts/:category/:theme' element={<Posts />} />
          <Route path='equipment' element={<Equipment />} />
          <Route path='modernizations' element={<Modernizations />} />
          <Route path='streamers' element={<Streamers />} />
          <Route path='about' element={<About />} />
          <Route path='contacts' element={<Contacts />} />
          <Route path='*' element={<Notfoundpage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
