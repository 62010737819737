import style from './SectionTitle.module.css';
import PropTypes from 'prop-types';

export const SectionTitle = ({text}) => (
  <h2 className={style.title}>{text}</h2>
);

SectionTitle.propTypes = {
  text: PropTypes.string,
};
