import Pagetitle from '../../components/Main/Pagetitle';

import style from './Streamers.module.css';

import korben from './img/korben.jpg';
import meanmachins from './img/meanmachins.jpg';
import nearyou from './img/nearyou.jpg';

export const Streamers = () => (
  <>
    <Pagetitle title='Стримеры' />
    <div className='container'>
      <div className={style.body}>
        <p className={style.text}>
          В настоящее время на популярных стриминговых площадках имеется
          множество парней и девушек, которые демонстрируют свои высокие навыки
          игры в World of Tanks (Мир танков). Вместе с тем только единицы из них
          являются высококлассными игроками, настоящими топами танкового
          киберспорта. Именно за такими танководами мы и следим, точнее
          отслеживаем их прямые трансляции и просматриваем их свежие видеоролики
          на Youtube, чтобы подглядеть используемые ими настройки полевой
          модернизации, выбранное дополнительное оборудование, а также
          внимательно слушаем объяснение причин такого выбора, чтобы потом
          внести полученную информацию в свою базу данных, доступ к которой мы
          и представляем всем желающим на нашем сайте.
        </p>
        <ul className={style.list}>
          <li className={style.item}>
            <img className={style.image} src={korben} />
            <div className={style.about}>
              <h3 className={style.itemTitle}>KorbenDallas</h3>
              <p className={style.text}>Как и большинство топовых стримеров,
                KorbenDallas старается не раскрывать подробные данные о себе и
                рассказывать о личной жизни. В настоящее время практически
                доподлинно известно, что реальное имя танкового асса Илья. О
                месте проживания какой-либо более-менее достоверной информации
                нам найти пока не удалось.</p>
              <p className={style.text}>KorbenDallas является обладателем
                одной из лучших игровых статистик в World of Tanks (Мир
                танков), авторитетным специалистом танковедения и владельцем
                нескольких стримерских каналов на Youtube и Trovo с почти
                полумиллионной аудиторией. Сразу скажем, что KorbenDallas не
                стесняется в крепких выражениях, что мы категорически не
                поддерживаем и, соответственно, не рекомендуем его видеоконтент
                к просмотру несовершеннолетними лицами.</p>
              <h4>Достижения:</h4>
              <p className={style.text}>
                - Лучший танкист 2014-2021 годов по версии игрового
                издательства &quot;Теплый ствол&quot;</p>
              <p className={style.text}>
                - Бронзовый призер Битвы Блогеров 2020 года</p>
              <p className={style.text}>
                - Победитель Битвы Блогеров 2021 года</p>
            </div>
          </li>

          <li className={style.item}>
            <div className={style.about}>
              <h3 className={style.itemTitle}>MeanMachins</h3>
              <p className={style.text}>Евгений Сорокин, самый взрослый
                танковый специалист в нашей библиотеке знаний. Бывший член
                клана [KOPM2], в составе которого он неоднократно принимал
                участие в крупных игровых турнирах. Кроме того, Евгений и
                самый культурный среди всех статистов. В ходе его
                онлайн-трансляций
                вы не услышите нецензурных ругательств, если только в
                исключительных случаях, когда реально без крепкого словца не
                обойтись.</p>
              <p className={style.text}>О MeanMachins информации достаточно.
                Он родился 19.01.1981 г., проживает в г. Барнаул, женат,
                воспитывает сына.</p>
              <p className={style.text}>Любимые танки Евгения: Leopard 1,
                Skoda T50/51, Об. 140, Udes 15/16, STB-1,CS-63.</p>
            </div>
            <img className={style.image} src={meanmachins} />
          </li>

          <li className={style.item}>
            <img className={style.image} src={nearyou} />
            <div className={style.about}>
              <h3 className={style.itemTitle}>Near_You</h3>
              <p className={style.text}>Алексей Кучкин - киберспортсмен и
                популярный танковый стример. Является многократным чемпионом
                различных международных турниров World of Tanks.</p>
              <p className={style.text}>Алексей не старается скрывать
                информацию о себе. Известно, что он родился 19.06.1996 г.,
                проживает в столице Республики Беларусь - городе Минске.</p>
              <p className={style.text}>Near_You активно стримит на Youtube
                и Trovo, имеет активную страничку в соцсети VK. Общая аудитория
                стримера насчитывает более 600 тыс. подписчиков.</p>
              <h4>Достижения:</h4>
              <p className={style.text}>
                - чемпион Wargaming.net League 2015 Grand Final</p>
              <p className={style.text}>
                -  чемпион World of Tanks Pro League</p>
              <p className={style.text}>
                - многократный чемпион Абсолютного Превосходства и Клановых
                Потасовок</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </>
);
