import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import {API_URL} from '../../api/const';
import Pagetitle from '../../components/Main/Pagetitle';
import Announce from '../../components/Announce';
import Button from '../../components/Button';

import style from './Posts.module.css';

export const Posts = () => {
  const {category, theme} = useParams();
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    axios(`${API_URL}queries/posts/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        category,
        theme,
      },
    })
      .then(({data}) => {
        setLoad(true);
        const postsList = [];
        let num = 1;

        for (const key in data) {
          if (key) {
            postsList.push({
              id: data[key].id,
              categoryID: data[key].categoryID,
              categoryName: data[key].categoryName,
              categoryTitle: data[key].categoryTitle,
              themeID: data[key].themeID,
              themeName: data[key].themeName,
              themeTitle: data[key].themeTitle,
              author: data[key].author,
              published: data[key].published,
              title: data[key].title,
              preview: data[key].preview,
              picture: data[key].picture,
              views: data[key].views,
              comments: data[key].comments,
              num,
            });
            num++;
          }
        }
        setInfo(postsList);
      })
      .catch((error) => console.log(error.message));
  }, [category, theme]);

  return (<>
    <Pagetitle title={category === 'news' ? 'Новости' : 'Статьи'} />
    <div className='container'>
      <div className={style.body}>
        {load && info.length > 0 ? info.map(elem => (
          <div key={elem.id} className={style.item}>
            <Announce size={elem.num === 3 || elem.num === 4 ?
              'big' : 'small'} content={elem} />
          </div>
        )) : 'Загрузка ...'}
      </div>
      <Button styles={style.more} className={style.more}
        page='/posts/news/all' value='Все новости' />
    </div>
  </>);
};

