import {useEffect, useState} from 'react';

export const Modernizations = () => {
  const [tanksListLoaded, setTanksListLoaded] = useState(false);
  const [answer, setAnswer] = useState([]);
  const query = '';

  useEffect(() => {
    fetch('https://edik.pro/wotbrains_api/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    })
      .then((response) => {
        if (response.ok) {
          setTanksListLoaded(true);
          setAnswer(response.json());
          console.log(answer);
        }
      })
      .catch((error) => console.log(error.message));
  }, [tanksListLoaded]);


  return (
    <>
      <h2>Полевая модернизация</h2>
    </>
  );
};
