import style from './Tanks.module.css';

import Pagetitle from '../../components/Main/Pagetitle';
import TankSelect from '../../components/TankSelect';

export const Tanks = () => (
  <>
    <Pagetitle title='База танков' />
    <div className='container'>
      <div className={style.body}>

        <TankSelect />

      </div>
    </div>
  </>
);
