import axios from 'axios';
import {API_URL} from '../../api/const.js';
import {updateTankslist} from './tankslistReducer';

export const tankslistRequestAsync = (national, tanktype, level) =>
  (dispatch) => {
    const url = `${API_URL}queries/tankslist/`;

    return axios(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        national,
        tanktype,
        level,
      },
    })
      .then(({data}) => {
        const list = [];

        for (const key in data) {
          if (key) {
            list.push({
              id: data[key].id,
              level: data[key].level,
              name: data[key].name,
              national: data[key].national,
              prem: data[key].prem,
              reward: data[key].reward,
              role: data[key].role,
              type: data[key].type,
              wotid: data[key].wotid,
            });
          }
        }

        dispatch(updateTankslist(list));
      })
      .catch((error) => console.log(error.message));
  };
