import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button';
import {API_URL} from '../../api/const';
import style from './Announce.module.css';

export const Announce = ({size, content}) => {
  const dateToISO = d => {
    const date = new Date(d);
    return date.toISOString();
  };

  return (
    <div className={classNames(style.item, style[size])}>
      <Link to={`/posts/${content.categoryName}/${content.themeID}`}
        className={style.category}>
        {content.themeTitle}
      </Link>
      <div className={style.picture}>
        <img src={`${API_URL}images/content/${content.picture}`}
          alt='Картинка' />
      </div>
      <div className={style.content}>
        <span className={style.autor}>{content.author}</span>
        <h3 className={classNames('title', style.title)}>{content.title}</h3>
        <div className={style.text}
          dangerouslySetInnerHTML={{__html: content.preview}}></div>
        <Button styles={style.button} value='Подробнее'
          page={`/content/news/${content.id}`} />
      </div>
      <div className={style.info}>
        <time className={style.date} dateTime={dateToISO(content.published)}>
          {content.published}
        </time>
        <span className={style.views}>{content.views}</span>
        <span className={style.comments}>{content.comments}</span>
      </div>
    </div>
  );
};

Announce.propTypes = {
  size: PropTypes.string,
  content: PropTypes.object,
};
