import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

import {API_URL} from '../../../../../api/const';
import style from './StreamerEquipment.module.css';

export const StreamerEquipment = ({tank}) => {
  const [load, setLoad] = useState(false);
  const [info, setInfo] = useState(false);
  const images = useSelector(state => state.tankbase.equipimg);

  useEffect(() => {
    axios(`${API_URL}queries/streamerequipment/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        id: tank,
      },
    })
      .then(({data}) => {
        setLoad(true);
        const equipmentTable = [];

        for (const key in data) {
          if (key) {
            equipmentTable.push({
              id: data[key].id,
              streamer: data[key].streamer,
              variant: data[key].variant,
              slot1: data[key].slot1,
              slot2: data[key].slot2,
              slot3: data[key].slot3,
            });
          }
        }

        setInfo(equipmentTable);
        console.log(info);
      })
      .catch((error) => console.log(error.message));
  }, [load]);

  return (
    <>{load && info.length > 0 ? info.map(elem => (
      <ul key={elem.id} className={style.body}>
        <li className={classNames(style.cell, style.streamer,
          style.rightBorder)}>
          {elem.streamer}
        </li>
        <li className={classNames(style.cell, style.variant,
          style.rightBorder)}>
          {elem.variant}
        </li>
        <li className={classNames(style.cell, style.slot,
          style.rightBorder)}>
          <img src={`https://${images[elem.slot1 - 1].url}`}
            className={style.image} alt={images[elem.slot1 - 1].name} />
        </li>
        <li className={classNames(style.cell, style.slot,
          style.rightBorder)}>
          <img src={`https://${images[elem.slot2 - 1].url}`}
            className={style.image} alt={images[elem.slot2 - 1].name} />
        </li>
        <li className={classNames(style.cell, style.slot)}>
          <img src={`https://${images[elem.slot3 - 1].url}`}
            className={style.image} alt={images[elem.slot3 - 1].name} />
        </li>
      </ul>
    )) : <div className={style.noinfo}>
      Информация об используемом стримерами оборудовании для данного танка
      в базе пока отсутствует
    </div>}</>
  );
};

StreamerEquipment.propTypes = {
  tank: PropTypes.string,
};
