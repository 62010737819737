import classNames from 'classnames';

import Button from '../Button';

import style from './Slider.module.css';

export const Slider = () => (
  <section className={style.slider}>
    <div className={style.inner}>
      <div className={classNames('container', style.body)}>
        <h2 className={classNames('title', style.title)}>
          Полевая модернизация
        </h2>
        <p className={style.text}>Наша команда постоянно собирает информацию
          о используемых топовыми игроками и стримерами настройках полевой
          модернизации, чтобы вы могли легко их использовать в своей игре.
          Для этого вам необходимо только выбрать конкретных танк в нашей
          базе данных, а мы предложим различные варианты полевой
          модернизации.</p>
        <Button styles={style.btn} value='Выбрать модернизацию'
          page='/tanks' />
      </div>
    </div>
  </section>
);
